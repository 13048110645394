<!--suppress ALL -->
<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      style="padding: 20px 30px"
      :title="__('Campaign')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <el-button
      v-show="!this.canWrite()"
      :disabled="!this.canWrite()"
      class="createBtn"
      >{{ __(`Create ${campaignFilter} Campaign`) }}</el-button
    >
    <el-dropdown
      v-show="this.canWrite()"
      split-button
      type="primary"
      :class="{ disabled: !this.canWrite() }"
      class="createCampaignDropDown"
      @click.stop="createCampaignItem(campaignFilter)"
      @command="createCampaignItem"
    >
      {{ __(`Create ${campaignFilter} Campaign`) }}
      <el-dropdown-menu slot="dropdown">
        <div>
          <el-tooltip
            effect="dark"
            placement="right"
            :content="tooltipMessage"
            :open-delay="500"
            :disabled="!this.canWrite()"
          >
            <span class="wrapper">
              <el-dropdown-item command="On-Demand" :disabled="!canWrite()">{{
                __("Create On-Demand Campaign")
              }}</el-dropdown-item>
            </span>
          </el-tooltip>
        </div>
        <div>
          <el-tooltip
            effect="dark"
            placement="right"
            :content="tooltipMessage"
            :open-delay="500"
            :disabled="this.canWrite()"
          >
            <span class="wrapper">
              <el-dropdown-item command="List-Based" :disabled="!canWrite()"
                >{{ __("Create List-Based Campaign") }}
              </el-dropdown-item>
            </span>
          </el-tooltip>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <div style="padding: 0 36px">
      <el-form class="form-container">
        <PaginationToolbar
          :key="campaignFilter"
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :auto-refresh-content="!modalCoverContent"
          :refresh-interval="5000"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar
        :native="true"
        v-loading="checkInUseLoading"
        :element-loading-text="__('checking usages') + '...'"
      >
        <div class="contentListContainer" v-loading="loading">
          <el-table
            ref="campaignsTable"
            class="list-table"
            :data="campaigns"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            row-key="campaign_id"
          >
            <el-table-column :label="__('Name')" min-width="300">
              <template slot-scope="scope">
                <div
                  style="width:100%; display: flex;justify-content: space-between"
                >
                  <div style="padding-right: 5px;">
                    {{ scope.row.campaign_name }}
                  </div>
                  <el-tag size="medium">{{
                    scope.row.campaign_channel
                  }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="__('Task')"
              prop="task_name"
              min-width="250"
            />
            <el-table-column
              :label="__('Caller Id')"
              prop="campaign_caller_id"
              min-width="150"
            />
            <el-table-column :label="__('Threads')" min-width="100">
              <template slot-scope="scope">
                {{
                  scope.row.campaign_channel === "voice"
                    ? scope.row.threads
                    : "NA"
                }}
              </template>
            </el-table-column>
            <el-table-column :label="__('Status')" min-width="120">
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <div
                    :style="getStatusStyles(scope.row.campaign_status)"
                    :class="
                      scope.row.campaign_status === 'running'
                        ? 'campaign-running'
                        : ''
                    "
                  ></div>
                  {{ capitalize(scope.row.campaign_status) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Message')" min-width="230">
              <template slot-scope="scope">
                <div
                  style="display: flex; align-items: center; word-break: break-word"
                >
                  {{ capitalize(scope.row.last_runtime_error) }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { EventBus } from "@/EventBus";

export default {
  name: "CampaignsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["campaign_name", "campaign_id"],
      tooltipMessage: __("You do not have permission to manage this item"),
      navItems: [
        {
          label: __("On-Demand"),
          value: "On-Demand",
          active: true,
          icon: "icon-campaign-ondemand.svg"
        },
        {
          label: __("List-Based"),
          value: "List-Based",
          active: false,
          icon: "icon-campaign-listbased.svg"
        }
      ]
    };
  },

  computed: {
    ...mapState("campaigns", {
      loading: state => state.loading,
      campaigns: state => state.campaigns,
      campaignFilter: state => state.campaignFilter
    }),
    ...mapState("folders", {
      checkInUseLoading: state => state.checkInUseLoading,
      checkInUseErrorMessage: state => state.checkInUseErrorMessage
    })
  },

  methods: {
    ...mapActions("campaigns", {
      contentAPI: "getCampaigns",
      deleteContentMethod: "deleteCampaign",
      undoDeleteContent: "undoDeleteCampaign",
      setCampaignFilter: "setCampaignFilter"
    }),

    capitalize(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },

    getStatusStyles(status) {
      let statusStyles = {
        width: "16px",
        height: "16px",
        "border-radius": "8px",
        "margin-right": "10px"
      };

      const statusColorMap = {
        new: "#D0EAFB",
        "new data": "#9CD6FC",
        verifying: "#4DB3F6",
        running: "#0583D6",
        paused: "#A2A2A2",
        stopped: "#454545",
        complete: "#51B848",
        ERROR: "#F84856",
        staged: "#7B8DFF"
      };

      statusStyles["background-color"] =
        statusColorMap[status] || "var(--theme-color)";
      return statusStyles;
    },

    handleNavItemChanged(navItem, refreshList = true) {
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { value: navItem.value }).active = true;
      if (this.campaignFilter !== navItem.value) {
        this.setCampaignFilter(navItem.value);
      }
      if (refreshList) {
        EventBus.$emit("list-changed");
      }
    },

    createCampaignItem(campaignType) {
      if (this.canAddEditOrDelete()) {
        this.setCampaignFilter(campaignType);
        this.$emit("open-create-modal", campaignType);
        EventBus.$emit("open-create-modal");
      } else {
        this.showPermissionError();
      }
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },
    handleClear() {
      this.$refs.campaignsTable && this.$refs.campaignsTable.setCurrentRow();
      this.handleClearSelection();
    }
  },

  watch: {
    campaignFilter: {
      immediate: true,
      handler: function(val) {
        this.handleNavItemChanged(
          {
            value: val
          },
          false
        );
      }
    },
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.campaignsTable &&
            this.$refs.campaignsTable.setCurrentRow(this.campaigns[val]);
        } else if (val === -1) {
          this.$refs.campaignsTable &&
            this.$refs.campaignsTable.setCurrentRow();
        }
      }
    },
    checkInUseErrorMessage: {
      handler() {
        if (this.checkInUseErrorMessage) {
          this.$message({
            type: "error",
            message: this.checkInUseErrorMessage
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #a1a3a950;
  border-radius: 3px;
}
@keyframes fade-in-fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.campaign-running {
  animation: fade-in-fade-out 1s infinite;
}

.createCampaignDropDown {
  position: absolute;
  top: 25px;
  right: 301px;
  border: none;
  color: white;
  background: $content-theme-color;
  border-radius: 6px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
