<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/CampaignActions";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "CampaignIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("campaigns", {
      contents: state => state.campaigns,
      campaignFilter: state => state.campaignFilter
    })
  },

  methods: {
    ...mapActions("campaigns", {
      setCampaignFilter: "setCampaignFilter"
    }),
    initContent() {
      return {
        campaign_name: "",
        campaign_type: this.campaignFilter,
        campaign_caller_id: "",
        threads: 0,
        dial_timeout: 20,
        campaign_status: "",
        last_runtime_error: "",
        last_processed_at: "2020-05-27 04:23:10",
        api_key: "",
        retry_count: 0,
        retry_busy_delay: 0,
        retry_error_delay: 0,
        task_id: "",
        from_number: "",
        display_name: "",
        max_threads: 0,
        campaign_passed_params: {
          data: []
        },
        campaign_schedules: {
          data: [
            {
              schedule_day: "Monday",
              start_time: "00:00",
              end_time: "23:55",
              after_hour_action: "Pause",
              skip_day: false
            },
            {
              schedule_day: "Tuesday",
              start_time: "00:00",
              end_time: "23:55",
              after_hour_action: "Pause",
              skip_day: false
            },
            {
              schedule_day: "Wednesday",
              start_time: "00:00",
              end_time: "23:55",
              after_hour_action: "Pause",
              skip_day: false
            },
            {
              schedule_day: "Thursday",
              start_time: "00:00",
              end_time: "23:55",
              after_hour_action: "Pause",
              skip_day: false
            },
            {
              schedule_day: "Friday",
              start_time: "00:00",
              end_time: "23:55",
              after_hour_action: "Pause",
              skip_day: false
            },
            {
              schedule_day: "Saturday",
              start_time: "00:00",
              end_time: "23:55",
              after_hour_action: "Pause",
              skip_day: false
            },
            {
              schedule_day: "Sunday",
              start_time: "00:00",
              end_time: "23:55",
              after_hour_action: "Pause",
              skip_day: false
            }
          ]
        }
      };
    },
    ...mapActions("variables", {
      fetchVariables: "forceFetchVariables"
    })
  },

  async created() {
    await this.fetchVariables();
  },

  mounted() {
    this.contentType = "Campaign";
    this.primaryKey = "campaign_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
