<template>
  <el-scrollbar :native="false">
    <div
      v-if="formAction"
      style="padding: 20px; max-height: calc(100vh - 40px)"
    >
      <div class="titles">{{ __("Campaign Name") }}</div>
      <div class="details">{{ contentFormInfo.campaign_name }}</div>
      <div class="titles">{{ __("Campaign Key") }}</div>
      <div class="details">
        <div class="api-key-item">
          <span class="api-key">{{ contentFormInfo.api_key }}</span>
          <span><copier :value="contentFormInfo.api_key"></copier></span>
        </div>
      </div>

      <div class="titles">{{ __("Task Name") }}</div>
      <div class="details">{{ contentFormInfo.task_name }}</div>
      <div class="titles" v-if="contentFormInfo.campaign_channel !== 'chatbot'">
        {{ __("Campaign Caller ID") }}
      </div>
      <div
        class="details"
        v-if="contentFormInfo.campaign_channel !== 'chatbot'"
      >
        {{ contentFormInfo.campaign_caller_id }}
      </div>
      <div
        class="titles"
        v-if="contentFormInfo.campaign_passed_params.data.length > 0"
      >
        {{ __("Passed Parameters") }}
      </div>
      <div v-if="contentFormInfo.campaign_passed_params.data.length > 0">
        <div
          v-for="param_id in contentFormInfo.campaign_passed_params.data"
          class="details"
          v-bind:key="param_id"
        >
          {{ getParameterNameFromId(param_id) }}
        </div>
      </div>
      <div v-if="contentFormInfo.campaign_channel === 'voice'">
        <div class="titles">
          {{ __("Threads") }}
        </div>
        <div class="details">
          {{ contentFormInfo.threads }}
        </div>
        <div class="titles">
          {{ __("Dial Timeout") }}
        </div>
        <div class="details">
          {{ contentFormInfo.dial_timeout }}
        </div>
        <div class="titles">
          {{ __("Retry Count") }}
        </div>
        <div class="details">
          {{
            contentFormInfo.retry_count === 0
              ? "disabled"
              : contentFormInfo.retry_count
          }}
        </div>
        <div v-if="contentFormInfo.retry_count">
          <div class="titles">
            {{ __("Retry Busy Delay") }}
          </div>
          <div class="details">
            {{
              contentFormInfo.retry_error_delay === 0
                ? "disabled"
                : contentFormInfo.retry_busy_delay + " mins"
            }}
          </div>
          <div class="titles">
            {{ __("Retry Error Delay") }}
          </div>
          <div class="details">
            {{
              contentFormInfo.retry_count === 0
                ? "disabled"
                : contentFormInfo.retry_error_delay + " mins"
            }}
          </div>
        </div>
      </div>
      <div class="titles">{{ __("Campaign Status") }}</div>
      <div class="details">{{ contentFormInfo.campaign_status }}</div>

      <div v-if="contentFormInfo.campaign_type === 'List-Based'">
        <div class="titles">{{ __("Campaign Schedule") }}</div>
        <div style="margin-top: 10px">
          <div
            v-for="campaign_schedule in campaignRunDays"
            style="display: flex; justify-content: space-between;"
            v-bind:key="campaign_schedule.schedule_id"
          >
            <div style="color: #6e7681;margin-top: 3px">
              {{ __(campaign_schedule.schedule_day) }}
            </div>
            <div style="display: flex;margin-top: 3px">
              <div style="color: #a0a8b5;font-size: 0.85em;">
                {{ campaign_schedule.start_time }}
              </div>
              <div style="color: #a0a8b5;font-size: 0.85em;">
                &nbsp;-&nbsp;
              </div>
              <div style="color: #a0a8b5;font-size: 0.85em;">
                {{ campaign_schedule.end_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; margin-bottom: 40px">
        <el-dropdown
          @command="handleCampaignAction"
          class="campaignActionDropDown"
        >
          <div>
            <span class="wrapper">
              <el-button type="primary" class="actionBtn">
                {{ __("Actions")
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
            </span>
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              :disabled="
                command.permission === 'write' ? !canWrite() : !canRead()
              "
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-dialog
        :visible.sync="openModal"
        v-if="openModal"
        :show-close="true"
        :before-close="notifyClosingOfModal"
        custom-class="editContentItemModal"
        destroy-on-close
        fullscreen
      >
        <component
          :campaign="contentForm"
          v-bind:is="component"
          :id="id"
          @cancel="handleCancel"
          :max-threads="max_threads"
        ></component>
      </el-dialog>
      <delete-item
        :show-force-delete="false"
        :title="__('Campaign in use')"
        :description="campaignInUseErrorDesc"
        key="campaign-delete-item"
        :delete-confirmation-event="deleteConfirmationEvent"
      ></delete-item>
    </div>
  </el-scrollbar>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { triggerAction } from "@/api/campaigns";
import Copier from "@/components/Copier";
import DeleteItem from "@/components/DeleteItem";
import { EventBus } from "@/EventBus";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  name: "CampaignActions",
  components: {
    Copier,
    PageHeader,
    DeleteItem
  },
  data() {
    return {
      max_threads: 0,
      deleteConfirmationEvent: "open-delete-confirmation-dialog-box-campaign",
      commands: [
        {
          command: "start",
          rules: {
            campaign_type: ["list-based"],
            campaign_status: ["new data", "stopped", "reset", "error"]
          },
          icon: "campaign_actions/icon_play.svg",
          label: __("Start Campaign")
        },
        {
          command: "stop",
          rules: {
            campaign_type: ["list-based"],
            campaign_status: ["running", "paused", "staged", "verifying"]
          },
          icon: "campaign_actions/icon_stop.svg",
          label: __("Stop Campaign")
        },
        {
          command: "reset",
          rules: {
            campaign_type: ["list-based"],
            campaign_status: ["stopped", "complete", "error"]
          },
          icon: "campaign_actions/icon_reset.svg",
          label: __("Reset Campaign")
        },
        {
          command: "createOrEditForm",
          rules: {},
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit"),
          permission: "write"
        },
        {
          command: "viewData",
          rules: {},
          icon: "campaign_actions/icon_view_data.svg",
          label: __("View Data")
        },
        {
          command: "apiInfo",
          rules: {},
          icon: "campaign_actions/icon_info_sign.svg",
          label: __("Api Info")
        },
        {
          command: "triggerCampaign",
          rules: {},
          icon: "campaign_actions/icon_call_out.svg",
          label: __("Make A Call")
        },
        {
          command: "uploadData",
          rules: { campaign_type: ["list-based"] },
          icon: "campaign_actions/icon_upload.svg",
          label: __("Upload Data")
        },
        {
          command: "delete",
          rules: {},
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Delete"),
          permission: "write"
        }
      ],
      rules: {
        campaign_name: [
          {
            required: true,
            message: __("Please input a Campaign name")
          },
          {
            max: 300,
            message: __("Length should be no more than 300 characters"),
            trigger: "blur"
          }
        ],
        task_id: [
          {
            required: true,
            message: __("Please select a task")
          }
        ],
        campaign_caller_id: [
          {
            required: true,
            message: __("Please select a Caller Id")
          }
        ]
      },
      selectedAction: "createOrEditForm",
      forceDeleteEventName: "initiate-force-delete-Campaign"
    };
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  },

  mounted() {
    this.getTasks({ fetch_all: 1, ignore_task_type_filter: 1 });
    this.getAcMaxThreads().then(max_threads => {
      this.max_threads = max_threads;
    });
  },

  methods: {
    ...mapActions("campaigns", {
      createCampaign: "createCampaign",
      updateCampaign: "updateCampaign",
      setSelectedCampaignId: "setSelectedCampaignId",
      deleteContentMethod: "deleteCampaign",
      undoDeleteContent: "undoDeleteCampaign",
      getAcMaxThreads: "getAcMaxThreads"
    }),
    ...mapActions("tasks", {
      getTasks: "getTasks"
    }),
    ...mapActions("phonenumbers", {
      getPhoneNumbers: "getPhoneNumbers"
    }),
    getParameterNameFromId(param_id) {
      return _.find(this.passedParameterVariables, variable => {
        return variable.variable_id === param_id;
      }).variable_name;
    },
    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },
    handleCampaignAction(action) {
      if (action === "delete") {
        this.handleDelete(
          this.contentFormInfo,
          "Campaign",
          this.contentFormInfo.campaign_id
        );
      } else if (["start", "stop", "reset"].includes(action)) {
        triggerAction(this.contentForm, action)
          .then(res => {
            this.$emit("handleSuccess");
            this.$message({
              message: res.data.message || "operation successful",
              type: "success"
            });
          })
          .catch(err => {
            this.$message({
              message: err.message || "operation failed",
              type: "error"
            });
          });
      } else {
        if (["createOrEditForm"].includes(action)) {
          this.$emit("edit-item");
        } else if (
          ["triggerCampaign", "uploadData", "viewData", "apiInfo"].includes(
            action
          )
        ) {
          this.$emit("extend-item");
        }
        this.selectedAction = action;
        this.handleOpenModal(true);
      }
    },
    importComponent(path) {
      return () => import("@/views/build/campaigns/pages/" + path);
    },
    backButtonHandler() {
      if (this.openModal) {
        this.handleCancel();
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables"
    }),
    component() {
      return this.importComponent(this.selectedAction);
    },
    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return rule.includes(this.contentFormInfo[key].toLowerCase());
        });
      });
    },
    campaignRunDays() {
      const schedules = this.contentFormInfo.campaign_schedules.data;
      return _.filter(schedules, schedule => !schedule.skip_day);
    },

    /**
     * Content for the campaign in use error description
     * @returns {String} Translated message for the campaign in use error description
     */
    campaignInUseErrorDesc() {
      // eslint-disable-next-line
      return __("This campaign cannot be deleted as it is getting used in some nodes. Deleting this campaign might break the associated tasks.");
    }
  },
  watch: {
    contentForm: {
      deep: true,
      immediate: true,
      handler: function(campaign) {
        this.setSelectedCampaignId(campaign.campaign_id);
      }
    },
    formAction: {
      immediate: true,
      handler: function(val) {
        if (val) {
          if (val === "edit" || val === "add") {
            this.selectedAction = "createOrEditForm";
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

/*.actionBtn.is-disabled {
  color: #ffffff;
  background-color: mix(#ffffff, $content-theme-color, 50%);
  border-color: $content-theme-outline-color !important;
}*/

.api-key-item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .api-key {
    word-break: break-word;
    padding-right: 5px;
  }
}

.campaignActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
    &:disabled {
      color: white;
      //background-color: mix(white, $content-theme-color, 50%);
      border-color: $content-theme-outline-color !important;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
